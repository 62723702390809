<template>
    <div>
        <div class='col-12'>
            <div class='flex justify-content-between flex-column sm:flex-row'>
                <h5>{{ dataTableModel ? '表格模式' : '曲线模式' }}</h5>
                <span class='p-input-icon-left mb-2'>
<!--                    <Button :label="!dataTableModel?'表格模式':'曲线模式'" icon='pi pi-sync' iconPos='left' @click='dataTableModel = !dataTableModel' />-->
                </span>
            </div>
            <div class='card'>
                <div class='flex-wrap align-items-center justify-content-center' :class="dataTableModel ? '':'hidden' ">
                    <div
                        class='scalein animation-duration-500 align-items-center justify-content-center font-bold text-white border-round'>
                        <DataTable :value='tableData' :paginator='true' class='p-datatable-gridlines'
                                   ref='dt'
                                   :lazy='true' @page='onPage($event)' @filter='onFilter($event)'
                                   :rows='15'
                                   paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                                   :rowsPerPageOptions='[15,25,50]'
                                   :totalRecords='totalRecords'
                                   currentPageReportTemplate='当前页显示第 {first} 到 第 {last} 条记录, 总共 {totalRecords} 条记录'
                                   dataKey='raw'
                                   :rowHover='true'
                                   v-model:filters='filters'
                                   :filters='filters' filterDisplay='menu' :loading='loading'
                                   responsiveLayout='scroll'
                                   scrollDirection='both'
                                   :globalFilterFields="['dataType','dateTime']">
                            <template #header>
                                <div class='flex justify-content-between flex-column sm:flex-row'>
                                    <div>
                                        <Button type='button' icon='pi pi-filter-slash' label='清除'
                                                class='p-button-outlined mb-2'
                                                @click='clearFilter($event)' />
                                        <!--                                        <Button type='button' icon='pi pi-external-link' label='导出'-->
                                        <!--                                                class='p-button-outlined mb-2 ml-3'-->
                                        <!--                                                @click='exportCSV($event)' />-->
                                    </div>
                                    <div>
                                        <MultiSelect v-model='selectedColumns' :options='colNameFilter'
                                                     optionLabel='value' placeholder='列显示控制' class='p-column-filter'
                                                     @change='selectedColumnsChange'>
                                            <template #option='slotProps'>
                                                <div class='p-multiselect-representative-option'>
                                                    <span class='image-text'>{{ slotProps.option.value }}</span>
                                                </div>
                                            </template>
                                        </MultiSelect>
                                    </div>
                                </div>
                            </template>
                            <template #empty>
                                未找到任何数据。选择 上传时间 可以查看其它时间的历史数据。
                            </template>
                            <template #loading>
                                <Row>
                                    Loading customers data. Please wait.
                                </Row>
                            </template>
                            <ColumnGroup type='header'>
                                <Row>

                                    <Column header='污染因子名称（单位）' colspan='2'></Column>
                                    <Column v-for='col of tableColumns' :key='col.field'
                                            :showFilterMatchModes='false' :colspan='selectedColumns.length+1'>
                                        <template #header=''>
                                            <span>{{ col.header }}</span>
                                            <span
                                                style='font-weight: normal;font-size: xx-small'>&nbsp;{{ col.unit === '' || col.unit === null ? '' : '(' + col.unit + ')'
                                                }}</span>
                                        </template>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column header='数据类型' field='dataType' :rowspan='1' filterField='dataType'
                                            :showFilterMatchModes='false' class='dataType' style='width: 200px;'>
                                        <template #filter='{filterModel}'>
                                            <div class='mb-3 font-bold'>选择数据的类型</div>
                                            <Dropdown v-model='filterModel.value' :options='dataTypeFilter'
                                                      optionLabel='value' placeholder='切换数据类型' />
                                        </template>
                                        <template #filterclear='{filterCallback}'>
                                            <Button type='button' icon='pi pi-times' @click='filterCallback()'
                                                    class='p-button-secondary' label='清除'></Button>
                                        </template>
                                        <template #filterapply='{filterCallback}'>
                                            <Button type='button' icon='pi pi-check' @click='filterCallback()'
                                                    class='p-button-primary' label='确定'></Button>
                                        </template>
                                    </Column>
                                    <Column header='上传时间' field='dateTime' :rowspan='1' filter-field='dateTime'
                                            :showFilterMatchModes='false' class='dateTime'>
                                        <template #filter='{filterModel}'>
                                            <Calendar v-model='filterModel.value' dateFormat='yy-mm-dd'
                                                      placeholder='选择时间' />
                                        </template>
                                        <template #filterclear='{filterCallback}'>
                                            <Button type='button' icon='pi pi-times' @click='filterCallback()'
                                                    class='p-button-secondary' label='清除'></Button>
                                        </template>
                                        <template #filterapply='{filterCallback}'>
                                            <Button type='button' icon='pi pi-check' @click='filterCallback()'
                                                    class='p-button-primary' label='确定'></Button>
                                        </template>
                                    </Column>
                                    <Column v-for='index of (tableColumns.length * 4)' :key='index'
                                            :header='getColName(index)'
                                            :class="showOrHidden(index) === 'show'? '':'hidden'"
                                            class='table-subtitle' />
                                </Row>
                            </ColumnGroup>
                            <Column field='dataType' class='table-row-font'></Column>
                            <Column field='dateTime' class='table-row-font'></Column>
                            <Column v-for='index of (tableColumns.length * 4)' :key='index'
                                    :field='getColField(index)'
                                    class='table-row-font' :class="showOrHidden(index) === 'show'? '':'hidden'" />
                        </DataTable>
                    </div>
                </div>

                <div class='flex-wrap align-items-center justify-content-center'
                     :class="!dataTableModel ? '':'hidden' ">
                    <div
                        class='scalein animation-duration-500 align-items-center justify-content-center font-bold text-white border-round'>
                        <Chart type='line' :data='lineData' :options='lineOptions'
                               style='position: relative; height:70vh;'></Chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerService from '../service/CustomerService';
import HistoryDataService from '../service/HistoryDataService';
import { FilterMatchMode } from 'primevue/api';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            dataTableModel: null,
            dataQueryParams: null,
            totalRecords: 0,
            tableColumns: [],
            selectedColumns: [],
            tableData: null,
            filters: {},
            loading: true,
            products: null,
            selectedProduct1: null,
            colNameFilter: [
                { key: '-max', value: '最大值' },
                { key: '-min', value: '最小值' },
                { key: '-cou', value: '累计值' },
            ],
            dataTypeFilter: [
                { key: '1', value: '实时数据' },
                { key: '2', value: '分钟数据' },
                { key: '3', value: '小时数据' },
                { key: '4', value: '日数据' },
            ],
            showRows: [
                { key: '10', value: 10 },
                { key: '15', value: 15 },
                { key: '20', value: 20 },
                { key: '30', value: 30 },
            ],
            lineData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'First Dataset',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: false,
                        backgroundColor: 'rgb(255, 205, 86)',
                        borderColor: 'rgb(255, 205, 86)',
                        tension: .4,
                    },
                    {
                        label: 'Second Dataset',
                        data: [28, 48, 40, 19, 86, 27, 90],
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgb(75, 192, 192)',
                        tension: .4,
                    },
                ],
            },
            lineOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#A0A7B5',
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            color: '#A0A7B5',
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        },
                    },
                    x: {
                        ticks: {
                            color: '#A0A7B5',
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        },
                    },
                },
            },
        };
    },
    customerService: null,
    historyDataService: null,
    created() {
        this.customerService = new CustomerService();
        this.historyDataService = new HistoryDataService();
        this.initFilters();
        this.dataTableModel = true;

    },
    mounted() {
        // this.customerService.getCustomersLarge().then(data => {
        //     this.customer1 = data;
        //     this.loading = false;
        //     this.customer1.forEach(customer => customer.date = new Date(customer.date));
        // });
        this.dataQueryParams = {
            'dataType': '3',
            'startTs': dayjs().startOf('day').valueOf(),
            'endTs': dayjs().valueOf(),
            'current': 1,
            'size': this.$refs.dt.rows,
        };
        this.loadHistoryDataTableData();
    },
    methods: {
        selectedColumnsChange() {
            console.log(this.selectedColumns);
        },
        getColField(index) {
            index = index - 1;

            let col = this.tableColumns[Math.floor((index / 4))];

            index = index % 4;
            let fieldStr = '';
            if (index === 0) {
                if (this.dataQueryParams.dataType === '1') {
                    return col.field + '-rtd';
                }
                fieldStr = col.field + '-avg';
            } else if (index === 1) {
                fieldStr = col.field + '-max';
            } else if (index === 2) {
                fieldStr = col.field + '-min';
            } else if (index === 3) {
                fieldStr = col.field + '-cou';
            }

            return fieldStr;
        },
        getColName(index) {
            index = index - 1;
            index = index % 4;
            if (index === 0) {
                if (this.dataQueryParams.dataType === '1') {
                    return '实时值';
                }
                return '平均值';
            } else if (index === 1) {
                return '最大值';
            } else if (index === 2) {
                return '最小值';
            } else if (index === 3) {
                return '累计值';
            }
        },
        loadHistoryDataTableData() {
            this.loading = true;
            this.historyDataService.historyDataTable(this.dataQueryParams).then(res => {
                if (res.data.success) {
                    this.totalRecords = res.data.data.page.total;
                    this.tableData = res.data.data.page.data;
                    const dataType = this.formatDataType(this.dataQueryParams.dataType);

                    this.tableData.forEach(d => {
                        d.dataType = dataType;
                        d.dateTime = dayjs(d.ts).format('YYYY-MM-DD HH:mm:ss');
                    });

                    let pollutionList = res.data.data.pollutionList;
                    this.tableColumns = [];
                    pollutionList.forEach(p => {
                        this.tableColumns.push({
                            'field': p.polId + '-' + p.polCode,
                            'header': p.polName !== null ? p.polName : p.polCode + '(未知编码)',
                            'unit': p.unit,
                        });
                    });
                    this.loading = false;
                } else {
                    this.$toast.add({ severity: 'error', summary: '错误提示', detail: '获取数据失败！', life: 3000 });
                }
            });
        },
        onPage(event) {
            this.dataQueryParams.current = event.page + 1;
            this.dataQueryParams.size = event.rows;
            this.loadHistoryDataTableData();
        },
        onSort(event) {
            this.lazyParams = event;
        },
        onFilter(event) {
            this.dataQueryParams.current = 1;
            if (event !== undefined && event.rows !== undefined) {
                this.dataQueryParams.size = event.rows;
            }
            if (this.filters.dataType.value) {
                this.dataQueryParams.dataType = this.filters.dataType.value.key;
            } else {
                this.dataQueryParams.dataType = '3';
            }

            if (this.filters.dateTime.value) {
                const time = this.filters.dateTime.value.getTime();
                this.dataQueryParams.startTs = dayjs(time).valueOf();
                this.dataQueryParams.endTs = dayjs(time).endOf('day').valueOf();
            } else {
                this.dataQueryParams.startTs = dayjs().startOf('day').valueOf();
                this.dataQueryParams.endTs = dayjs().valueOf();
            }

            this.loadHistoryDataTableData();
        },

        formatDataType(type) {
            if (type === '1') {
                return '实时数据';
            } else if (type === '2') {
                return '分钟数据';
            } else if (type === '3') {
                return '小时数据';
            } else if (type === '4') {
                return '日数据';
            } else {
                return '全部';
            }
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                'dataType': { value: null, matchMode: FilterMatchMode.EQUALS },
                'dateTime': { value: null, matchMode: FilterMatchMode.EQUALS },
            };
        },
        clearFilter() {
            this.initFilters();
            this.onFilter();
        },
        showOrHidden(index) {
            index = index - 1;
            index = index % 4;

            const arr = [];
            this.selectedColumns.forEach(e => arr.push(e.key));

            if (index === 0) {
                return 'show';
            } else if (index === 1) {
                return arr.indexOf('-max') >= 0 ? 'show' : '';
            } else if (index === 2) {
                return arr.indexOf('-min') >= 0 ? 'show' : '';
            } else if (index === 3) {
                return arr.indexOf('-cou') >= 0 ? 'show' : '';
            }
            return 'show';
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        formatDate(value) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        stateClass(state) {
            return [
                {
                    'normalState': state === '正常',
                    'failState': state !== '正常',
                },
            ];
        },
    },
};
</script>

<style lang='scss' scoped>
.normalState {
    font-weight: 700;
    color: #0BD18A;
}

.failState {
    font-weight: 700;
    color: #FC6161;
}

::v-deep .table-row-font {
    //font-size: small;
}

::v-deep .dataType .p-column-header-content {
    width: 100px;
}

::v-deep .dateTime .p-column-header-content {
    width: 150px;
}

::v-deep .table-subtitle .p-column-header-content {
    width: 60px;
    font-size: xx-small;
}

@media screen and (max-width: 960px) {
    .panel-demo {
        .toolbar-demo.card {
            overflow: auto;
        }
    }
}

@keyframes fadeinleft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
        transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}
</style>